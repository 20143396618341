import { Component, EventEmitter, Input, Output } from '@angular/core'
import { formatBytes } from '@engineering11/utility'
import { PlayerSize, VideoPlayerResource } from '@engineering11/multimedia-web'
import { IUploadedVideo } from '../../../model/candidate-video.model'

export type VideoDetail = Pick<IUploadedVideo, 'name' | 'videoLength' | 'video' | 'thumb'>

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-video-detail',
  templateUrl: './ui-video-detail.component.html',
  styleUrls: ['./ui-vide-detail.component.scss'],
})
export class UiVideoDetailComponent {
  _video!: VideoDetail

  @Input()
  showContent = false

  @Output()
  closeFired: EventEmitter<boolean> = new EventEmitter()

  @Output()
  deleteFired: EventEmitter<boolean> = new EventEmitter()

  showDeleteModal = false
  playerSizeEnum = PlayerSize

  videoSize = 'N/A'

  @Input() title = 'First Impression Video'

  @Input() set video(value: VideoDetail) {
    if (value) {
      this._video = value
      if (this._video.video.size) {
        this.videoSize = formatBytes(this._video.video.size)
      }
    }
  }

  @Input() videoPlayerResource?: VideoPlayerResource

  constructor() {}

  onClose() {
    this.showContent = false
  }

  delete() {
    this.showDeleteModal = true
  }
}
