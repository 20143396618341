import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core'
import { FilesService, IFileReceipt } from '@engineering11/files-web'
import { PlayerSize, VideoPlayerResource } from '@engineering11/multimedia-web'
import { E11ModalComponent, E11ModalGlobalService } from '@engineering11/ui-lib/e11-modal'
import { Size } from '@engineering11/ui-lib/e11-ui-config'
import { deepCopy } from '@engineering11/utility'
import { Subject, takeUntil } from 'rxjs'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IFirstImpression } from '../../model/interfaces'

@Component({
  selector: 'single-first-impression',
  template: `
    <div *ngIf="loaded">
      <single-first-impression-card
        [firstImpression]="signedFirstImpression"
        [userMode]="userMode"
        [openRequest]="openRequest"
        (openVideoPlayer)="openVideoPlayer($event)"
        (openRequestUpdate)="openRequestUpdate.emit(true)"
        [readonly]="readOnly"
        [size]="size"
        [displayRequestUpdate]="displayRequestUpdate"
      ></single-first-impression-card>
      <div class="e11-h-[400px]" *ngIf="showPlayer && userMode === enumUserMode.Public">
        <video-player
          *ngIf="showPlayer && userMode === enumUserMode.Public"
          [thumb]="thumb"
          [playerSize]="playerSizeEnum.Small"
          [videoPlayerResource]="videoPlayerResource"
        >
        </video-player>
      </div>
    </div>

    <div *ngIf="!loaded" class="e11-panel-skeleton"></div>
    <ng-template #firstImpressionModal>
      <div modal-body class="video-container e11-h-full e11-p-0">
        <video-player
          *ngIf="showPlayer"
          [thumb]="thumb"
          [playerSize]="playerSizeEnum.Small"
          [videoPlayerResource]="videoPlayerResource"
        ></video-player>
      </div>
    </ng-template>
  `,
  styleUrls: ['./single-first-impression.component.scss'],
})
export class SingleFirstImpressionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() firstImpression?: IFirstImpression | null
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() size?: Size | undefined
  @Input() readOnly = false
  @Input() displayRequestUpdate = false

  @Input() openRequest?: RequestedUpdateFields
  @Input() showRequestButton = true

  @Output() openRequestUpdate: EventEmitter<boolean> = new EventEmitter<boolean>()
  @ViewChild('modalItem') modal!: E11ModalComponent
  @ViewChild('firstImpressionModal') firstImpressionModal!: TemplateRef<any>

  signedFirstImpression?: IFirstImpression
  playerSizeEnum = PlayerSize
  loaded = false
  showPlayer = false
  videoPlayerResource?: VideoPlayerResource
  thumb?: string
  enumUserMode = UserMode
  destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(private filesService: FilesService, private modalService: E11ModalGlobalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAndSignFirstImpression()
  }

  async ngOnInit(): Promise<void> {
    this.loadAndSignFirstImpression()
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  async loadAndSignFirstImpression() {
    if (this.firstImpression) {
      this.firstImpression = deepCopy(this.firstImpression) // Need this because signThumbnail mutates the original state
      this.signedFirstImpression = await this.signThumbnail(this.firstImpression!) // The input data could be overwritten
    } else {
      this.signedFirstImpression = undefined
    }
    this.loaded = true
  }

  async signThumbnail(firstImpression: IFirstImpression): Promise<IFirstImpression> {
    let fileReceipts = new Array<IFileReceipt>()
    if (firstImpression.thumb) {
      fileReceipts = await this.filesService.signedURLs([firstImpression.thumb])
      firstImpression.thumb.signedURL = fileReceipts[0].signedURL
    }
    return firstImpression
  }

  async openVideoPlayer(firstImpression: IFirstImpression) {
    if (firstImpression.thumb?.signedURL && firstImpression.video) {
      this.thumb = firstImpression.thumb?.signedURL
      Promise.all([this.filesService.signedURLs([firstImpression.video]), this.filesService.getVideoFallbackURL(firstImpression.video)]).then(
        ([signedVideo, fallbackUrl]) => {
          if (signedVideo.length > 0 && signedVideo[0].signedURL) {
            this.videoPlayerResource = {
              video: signedVideo[0].signedURL,
              fallbackVideoUrl: fallbackUrl,
            }
          }
        }
      )

      if (this.userMode !== this.enumUserMode.Public) {
        this.modalService.openModal({
          body: this.firstImpressionModal,
          width: 'xl',
          bodyHeight: 'auto',
          showHeader: false,
          hideCloseButton: false,
          closer: true,
          showFooter: false,
        })
        this.modalService.modalClosed$.pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.videoPlayerResource = undefined
          this.showPlayer = false
        })
      }
      this.showPlayer = true
    }
  }
}
