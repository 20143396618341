import { ICandidateApplicationUpdateStage, ICandidateUpdateModel } from '@candidate/app/models/candidate-job-update.model'
import { ICandidateResumeData } from '@candidate/app/models/candidate-resume.model'
import { ICandidateWithdrawApplication } from '@candidate/app/modules/applications/components/application-withdraw-modal/models'
import { AtLeast } from '@engineering11/types'
import { Action } from '@ngrx/store'
import {
  IApplicationHistory,
  ICandidateApplicationDetails,
  ICandidateJobSubmission,
  ICandidateJobSubmissionPreview,
  IJobApplicationStage,
  IProcessedJobApplication,
  IVirtualDialogue,
} from 'shared-lib'

export enum ApplicationActionTypes {
  applicationChooseResume = '[Application Choose Resume Page] choose resume for application',
  updateCandidateResponses = '[Job Application Page] update candidate responses',
  updateCandidateComplianceResponses = '[Job Application Page] update candidate compliance responses',
  getApplicationStage = '[Application Effects] get application stage',
  getApplicationStageSuccess = '[Application Effects] get application stage success',
  getApplicationStageSuccessWithEmpty = '[Application Effects] get application stage success with empty',
  getApplicationHistory = '[Application Effects] get application history',
  getApplicationHistorySuccess = '[Application Effects] get application history success',
  createApplicationStage = '[Application Effects] create application stage',
  createApplicationStageSuccess = '[Application Effects] create application stage success',
  updateApplicationStage = '[Application Effects] update application stage',
  updateApplicationStageSuccess = '[Application Effects] update application stage success',
  updateApplicationCandidateDetails = '[Manage Application] update candidate details',
  updateApplicationPhoto = '[Manage Application] update photo',
  updateApplicationMilitaryAffiliations = '[Manage Application] update military affiliations',
  addApplicationSkillsAndCerts = '[Review Application] add skills and certs',
  updateApplicationSkills = '[Manage Application] update skills',
  updateApplicationCertifications = '[Manage Application] update certifications',
  updateApplicationWorkHistory = '[Manage Application] update work history',
  updateApplicationLanguage = '[Manage Application] update languages',
  updateApplicationEducation = '[Manage Application] update education',
  updateApplicationFirstImpression = '[Manage Application] update first impression',
  updateApplicationReferences = '[Manage Application] update references',
  updateApplicationPortfolio = '[Manage Application] update portfolio',
  updateApplicationSocialMedia = '[Manage Application] update social media',
  processJobApplicationPreview = '[Job Application Page] process job application preview',
  processJobApplicationPreviewSuccess = '[Application Effects] process job application preview success',
  submitJobApplication = '[Job Application Page] submit job application',
  submitJobApplicationSuccess = '[Application Effects] submit job application success',
  getJobApplication = '[Application Effects] get existing job application',
  getJobApplicationSuccess = '[Application Effects] get existing job application success',
  getStagedApplicationUpdate = '[Application Effects] get staged application update',
  getStagedApplicationUpdateSuccess = '[Application Effects] get staged application update success',
  stageApplicationUpdate = '[Application Update Page] stage application update',
  stageApplicationUpdateSuccess = '[Application Effects] stage application update success',
  submitApplicationUpdate = '[Application Update Page] submit application update',
  submitApplicationUpdateSuccess = '[Application Effects] submit application update success',
  withdrawApplication = '[Application Detail Page] withdraw application',
  withdrawApplicationSuccess = '[Application Effects] withdraw application success',
  undoWithdrawal = '[Application Page] undo application withdrawal',
  undoWithdrawalSuccess = '[Application Effects] undo application withdrawal success',
  getSelectedApplicationVirtualDialogues = '[Application Effects] get virtual interview sessions',
  getSelectedApplicationVirtualDialoguesSuccess = '[Application Effects] get virtual interview sessions success',

  error = '[Job Application Effects] error',
}

export const UPDATE_APPLICATION_ACTION_TYPES = [
  ApplicationActionTypes.updateApplicationStage,
  ApplicationActionTypes.updateApplicationCandidateDetails,
  ApplicationActionTypes.updateApplicationPhoto,
  ApplicationActionTypes.updateApplicationMilitaryAffiliations,
  ApplicationActionTypes.updateApplicationSkills,
  ApplicationActionTypes.updateApplicationCertifications,
  ApplicationActionTypes.updateApplicationWorkHistory,
  ApplicationActionTypes.updateApplicationLanguage,
  ApplicationActionTypes.updateApplicationEducation,
  ApplicationActionTypes.updateApplicationReferences,
  ApplicationActionTypes.updateApplicationFirstImpression,
  ApplicationActionTypes.updateApplicationPortfolio,
  ApplicationActionTypes.updateApplicationSocialMedia,
  ApplicationActionTypes.updateCandidateResponses,
  ApplicationActionTypes.updateCandidateComplianceResponses,
]

export class ApplicationChooseResume implements Action {
  readonly type = ApplicationActionTypes.applicationChooseResume
  constructor(public payload: ICandidateResumeData) {}
}

export class UpdateCandidateResponses implements Action {
  readonly type = ApplicationActionTypes.updateCandidateResponses
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'candidateResponses'>) {}
}

export class UpdateCandidateComplianceResponses implements Action {
  readonly type = ApplicationActionTypes.updateCandidateComplianceResponses
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'candidateComplianceResponses'>) {}
}

export class GetApplicationStage implements Action {
  readonly type = ApplicationActionTypes.getApplicationStage
  constructor(public jobPostId: string) {}
}

export class GetApplicationHistory implements Action {
  readonly type = ApplicationActionTypes.getApplicationHistory
  constructor(public jobPostId: string) {}
}

export class GetApplicationHistorySuccess implements Action {
  readonly type = ApplicationActionTypes.getApplicationHistorySuccess
  constructor(public payload: IApplicationHistory[]) {}
}

export class GetApplicationStageSuccess implements Action {
  readonly type = ApplicationActionTypes.getApplicationStageSuccess
  constructor(public payload: IJobApplicationStage) {}
}

export class GetApplicationStageSuccessWithEmpty implements Action {
  readonly type = ApplicationActionTypes.getApplicationStageSuccessWithEmpty
}

export class CreateApplicationStage implements Action {
  readonly type = ApplicationActionTypes.createApplicationStage
  constructor(public payload: AtLeast<IJobApplicationStage, 'jobPostId'>) {}
}

export class CreateApplicationStageSuccess implements Action {
  readonly type = ApplicationActionTypes.createApplicationStageSuccess
  constructor(public payload: IJobApplicationStage) {}
}

export class UpdateApplicationStage implements Action {
  readonly type = ApplicationActionTypes.updateApplicationStage
  constructor(public payload: AtLeast<IJobApplicationStage, 'jobPostId'>) {}
}

export class UpdateApplicationStageSuccess implements Action {
  readonly type = ApplicationActionTypes.updateApplicationStageSuccess
  constructor(public payload: AtLeast<IJobApplicationStage, 'jobPostId' | 'candidateId'>) {}
}

export class UpdateApplicationCandidateDetails implements Action {
  readonly type = ApplicationActionTypes.updateApplicationCandidateDetails
  constructor(public payload: ICandidateApplicationDetails & Pick<IJobApplicationStage, 'jobPostId'>) {}
}

export class UpdateApplicationPhoto implements Action {
  readonly type = ApplicationActionTypes.updateApplicationPhoto
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'photoURL'>) {}
}

export class UpdateApplicationMilitaryAffiliations implements Action {
  readonly type = ApplicationActionTypes.updateApplicationMilitaryAffiliations
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'militaryAffiliation'>) {}
}

export class UpdateApplicationSkills implements Action {
  readonly type = ApplicationActionTypes.updateApplicationSkills
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'skills'>) {}
}

export class UpdateApplicationCertifications implements Action {
  readonly type = ApplicationActionTypes.updateApplicationCertifications
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'certifications'>) {}
}

export class AddApplicationSkillsAndCerts implements Action {
  readonly type = ApplicationActionTypes.addApplicationSkillsAndCerts
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'certifications' | 'skills'>) {}
}

export class UpdateApplicationWorkHistory implements Action {
  readonly type = ApplicationActionTypes.updateApplicationWorkHistory
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'workHistory'>) {}
}

export class UpdateApplicationLanguage implements Action {
  readonly type = ApplicationActionTypes.updateApplicationLanguage
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'languages'>) {}
}

export class UpdateApplicationEducation implements Action {
  readonly type = ApplicationActionTypes.updateApplicationEducation
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'education'>) {}
}

export class UpdateApplicationFirstImpression implements Action {
  readonly type = ApplicationActionTypes.updateApplicationFirstImpression
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'firstImpression'>) {}
}

export class UpdateApplicationPortfolio implements Action {
  readonly type = ApplicationActionTypes.updateApplicationPortfolio
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'portfolio'>) {}
}

export class UpdateApplicationReferences implements Action {
  readonly type = ApplicationActionTypes.updateApplicationReferences
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'references'>) {}
}

export class UpdateApplicationSocialMedia implements Action {
  readonly type = ApplicationActionTypes.updateApplicationSocialMedia
  constructor(public payload: Pick<IJobApplicationStage, 'jobPostId' | 'socialMedia'>) {}
}

export class ProcessJobApplicationPreview implements Action {
  readonly type = ApplicationActionTypes.processJobApplicationPreview
  constructor(public payload: ICandidateJobSubmissionPreview) {}
}

export class ProcessJobApplicationPreviewSuccess implements Action {
  readonly type = ApplicationActionTypes.processJobApplicationPreviewSuccess
  constructor(public payload: IProcessedJobApplication) {}
}

export class SubmitJobApplication implements Action {
  readonly type = ApplicationActionTypes.submitJobApplication
  constructor(public payload: ICandidateJobSubmission) {}
}

export class SubmitJobApplicationSuccess implements Action {
  readonly type = ApplicationActionTypes.submitJobApplicationSuccess
  constructor(public payload: IProcessedJobApplication) {}
}

export class GetJobApplication implements Action {
  readonly type = ApplicationActionTypes.getJobApplication
  constructor(public jobPostId: string) {}
}

export class GetJobApplicationSuccess implements Action {
  readonly type = ApplicationActionTypes.getJobApplicationSuccess
  constructor(public payload: IProcessedJobApplication | null) {}
}

export class GetStagedApplicationUpdate implements Action {
  readonly type = ApplicationActionTypes.getStagedApplicationUpdate
  constructor(public jobPostId: string) {}
}
export class GetStagedApplicationUpdateSuccess implements Action {
  readonly type = ApplicationActionTypes.getStagedApplicationUpdateSuccess
  constructor(public payload: ICandidateApplicationUpdateStage) {}
}

export class StageApplicationUpdate implements Action {
  readonly type = ApplicationActionTypes.stageApplicationUpdate
  constructor(public payload: AtLeast<ICandidateApplicationUpdateStage, 'jobPostId' | 'candidateId'>) {}
}

export class StageApplicationUpdateSuccess implements Action {
  readonly type = ApplicationActionTypes.stageApplicationUpdateSuccess
  constructor(public payload: ICandidateApplicationUpdateStage) {}
}

export class SubmitApplicationUpdate implements Action {
  readonly type = ApplicationActionTypes.submitApplicationUpdate
  constructor(public payload: ICandidateUpdateModel) {}
}

export class SubmitApplicationUpdateSuccess implements Action {
  readonly type = ApplicationActionTypes.submitApplicationUpdateSuccess
  constructor(public payload: ICandidateUpdateModel) {}
}

export class WithdrawApplication implements Action {
  readonly type = ApplicationActionTypes.withdrawApplication
  constructor(public payload: ICandidateWithdrawApplication) {}
}

export class WithdrawApplicationSuccess implements Action {
  readonly type = ApplicationActionTypes.withdrawApplicationSuccess
  constructor() {}
}

export class UndoWithdrawal implements Action {
  readonly type = ApplicationActionTypes.undoWithdrawal
  constructor() {}
}

export class UndoWithdrawalSuccess implements Action {
  readonly type = ApplicationActionTypes.undoWithdrawalSuccess
  constructor(public application: IProcessedJobApplication) {}
}

export class GetSelectedApplicationVirtualDialogues implements Action {
  readonly type = ApplicationActionTypes.getSelectedApplicationVirtualDialogues
  constructor(public jobPostId: string) {}
}

export class GetSelectedApplicationVirtualDialoguesSuccess implements Action {
  readonly type = ApplicationActionTypes.getSelectedApplicationVirtualDialoguesSuccess
  constructor(public payload: IVirtualDialogue[]) {}
}

export class ErrorAction implements Action {
  readonly type = ApplicationActionTypes.error
  constructor(public payload: any) {}
}
export type ApplicationActions =
  | ApplicationChooseResume
  | GetApplicationStage
  | GetApplicationStageSuccess
  | GetApplicationStageSuccessWithEmpty
  | GetApplicationHistory
  | GetApplicationHistorySuccess
  | CreateApplicationStage
  | CreateApplicationStageSuccess
  | UpdateApplicationAction
  | UpdateApplicationStageSuccess
  | ProcessJobApplicationPreview
  | ProcessJobApplicationPreviewSuccess
  | SubmitJobApplication
  | SubmitJobApplicationSuccess
  | GetJobApplication
  | GetJobApplicationSuccess
  | GetStagedApplicationUpdate
  | GetStagedApplicationUpdateSuccess
  | GetSelectedApplicationVirtualDialogues
  | GetSelectedApplicationVirtualDialoguesSuccess
  | StageApplicationUpdate
  | StageApplicationUpdateSuccess
  | SubmitApplicationUpdate
  | SubmitApplicationUpdateSuccess
  | WithdrawApplication
  | WithdrawApplicationSuccess
  | ErrorAction

export type UpdateApplicationAction =
  | UpdateApplicationStage
  | UpdateApplicationCandidateDetails
  | UpdateApplicationPhoto
  | UpdateApplicationSkills
  | UpdateApplicationCertifications
  | UpdateApplicationWorkHistory
  | UpdateApplicationEducation
  | UpdateApplicationReferences
  | UpdateApplicationFirstImpression
  | UpdateApplicationPortfolio
  | UpdateApplicationSocialMedia
