import { ElementRef, Injectable } from '@angular/core'
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router } from '@angular/router'
import { APP_NAVIGATION_LEFT_GROUPS } from '@employer/app/services/nav-left.service'
import { PermissionStore } from '@engineering11/access-web'
import { ConfigStore, IFeatures } from '@engineering11/config-web'
import { CustomerPermissions } from '@engineering11/customer-web'
import {
  INavigationItem,
  e11MenuItemWithRouter,
  e11MenuItemWithRouterWithAction,
  e11MenuItemWithTemplateKey,
} from '@engineering11/ui-lib/e11-nav-left'
import { isNotNil } from '@engineering11/utility'
import { ViewportService } from '@engineering11/web-utilities'
import { BehaviorSubject, combineLatest, filter, map } from 'rxjs'
import { JobAutomationPermissions, JobAutomationRolePermissions } from 'shared-lib'
import { AllPermissions, Permission } from '../config/permission.config'

export const ROUTES = Object.freeze({
  HOME: '/home',
  JOBS: '/jobs/list',
  JOBS_BASE: '/jobs',
  JOB_POST: '/jobs/post',
  JOB_PROFILE: '/jobs/profile',
  JOB_CREATE: '/jobs/create',
  JOBS_BOOST: '/jobs/promotions',
  JOBS_TEMPLATE: '/jobs/template/list',
  JOBS_NOT_FOUND: '/jobs/404',
  APPLICATIONS: '/jobs/applications',
  CANDIDATE_POOL: '/candidate-pool/search',
  CANDIDATE_POOL_BASE: '/candidate-pool/',
  CANDIDATE_POOL_MESSAGE: '/candidate-pool/candidates',
  MANAGE_USERS: '/users',
  CLIENT_MANAGEMENT: '/customer-clients',
  COMPANY_ACCOUNT: '/customer',
  COMPANY_LOGO: '/customer/logo',
  COMPANY_HERO_SECTION: '/customer/hero',
  COMPANY_CONTENT: '/customer/content',
  COMPANY_DISCLAIMER: '/customer/disclaimer',
  COMPANY_LOCATIONS: '/customer/locations',
  COMPANY_DEPARTMENTS: '/customer/departments',
  HIRING_TEAM_SETTINGS: '/account/hiring-team-settings',
  SOCIAL_MEDIA: '/customer/social',
  IQ_LIBRARY: '/customer/interview-questions',
  COMPANY_COMPLIANCE: '/customer/compliance',
  COMPANY_AUTOMATION_SETTINGS: '/customer/automation-settings',
  COMPANY_CAREERS: '/customer/careers',
  ACCOUNT: '/account',
  LOGIN: '/auth/login',
  PROMOTIONS: '/jobs/promotions',
  USER_PERSONAL: '/account',
  USER_PIC: '/account/profile-pic',
  USER_SECURITY: '/account/security',
  USER_NOTIFICATIONS: '/account/notifications',
  USER_VIDEO: '/account/profile-video',
  NOT_FOUND: '/home/404',
  REPORTS: '/reports',
})

export const CAREERS_NAV_ITEM = [ROUTES.COMPANY_CONTENT, ROUTES.COMPANY_LOGO, ROUTES.COMPANY_HERO_SECTION, ROUTES.SOCIAL_MEDIA]
export const FEATURE_FLAGGED_NAV = {
  EmployerUserProfileVideo: 'employerUserProfileVideo',
  InterviewQuestionLibrary: 'employerInterviewQuestions',
  EmployerJobPromotions: 'employerJobPromotions',
  CandidatePool: 'candidatePool',
  JobPostAutomation: 'employerJobAutomation',
  EmployerReporting: 'employerReporting',
}

export enum NavItemNames {
  Home = 'Home',
  Jobs = 'Jobs',
  Promotions = 'Promotions',
  JobTemplates = 'Job Templates',
  Applications = 'Application Search',
  CandidatePool = 'Candidate Pool',
  CPSearch = 'Candidate Search',
  CPMessages = 'Candidate Messages',
  ManageUsers = 'Manage Users',
  CareersPage = 'Careers Page',
  CompanyAccount = 'Company Settings',
  HiringTeamSettings = 'Hiring Team Settings',
  ClientManagement = 'Client Management',
  UserAccount = 'My Account',
  FileNotFound = 'Oops! Page Not Found',
  Reports = 'Reports',
}

export enum CandidateNavigationSource {
  List = 'list',
  Search = 'search',
}

export interface INavItems {
  name: NavItemNames
  altParent?: Array<string>
  navGroup?: APP_NAVIGATION_LEFT_GROUPS
  path: string
  icon: string
  children?: Array<{
    label: string
    path: string
    queryParams?: Params
    featureFlagged?: string
    permission?: string
  }>
}

export enum CandidateDetailTabs {
  Profile = 'Profile',
  Notes = 'Notes / Files',
  RequestMoreInfo = 'Request Info',
  DirectMessage = 'Direct Message',
  Dialogues = 'Interview Sessions',
  ApplicationHistory = 'Application History',
  Discussion = 'Discussion',
  Feedback = 'Feedback',
}

export enum CandidatePoolTabs {
  Profile = 'Profile',
  DirectMessage = 'Direct Message',
}

export type TypeOfNav = 'homeNav' | 'companyNav' | 'clientNav' | 'userAccountMenus'

const pattern = (segment: string) => new RegExp(`^${segment}(\\/|\\?|$|\\S+)`)

const routePatternMatched = (segment: string | string[], testRoutePath: string) => {
  if (!Array.isArray(segment)) {
    return pattern(segment).test(testRoutePath)
  }
  return segment.some(urlSegment => pattern(urlSegment).test(testRoutePath))
}

@Injectable({ providedIn: 'root' })
export class EmployerNavigationService {
  candidateNavigationSource: BehaviorSubject<CandidateNavigationSource> = new BehaviorSubject<CandidateNavigationSource>(
    CandidateNavigationSource.List
  )
  candidateNavigationSourceObservable$ = this.candidateNavigationSource.asObservable()

  userPermissions$ = this.permissionStore.userPermissions$

  userHasPermission$ = (permission: AllPermissions) =>
    this.userPermissions$.pipe(map(permissions => (permissions ? permissions.has(permission) : false)))
  features$ = (feature: keyof IFeatures) => this.configStore.features$.pipe(map(features => (features ? features[feature] : false)))
  menuItemActions = {
    homeMenuItem: e11MenuItemWithRouterWithAction(() => this.changeActive('homeNav'), NavItemNames.Home, ROUTES.HOME, 'home'),
    reportsMenuItem: e11MenuItemWithRouterWithAction(
      () => this.changeActive('homeNav'),
      NavItemNames.Reports,
      ROUTES.REPORTS,
      'bar_chart',
      this.features$(FEATURE_FLAGGED_NAV.EmployerReporting),
      'footer'
    ),
    companyMenuItem: e11MenuItemWithRouterWithAction(
      () => this.changeActive('companyNav'),
      'Company Settings',
      ROUTES.COMPANY_ACCOUNT,
      'settings',
      this.userHasPermission$(Permission.COMPANY_MANAGE),
      'footer'
    ),
    clientManagementMenuItem: e11MenuItemWithRouter(
      'Client Management',
      ROUTES.CLIENT_MANAGEMENT,
      'manage_accounts',
      this.userHasPermission$(CustomerPermissions.ViewClient),
      'footer'
    ),
  }

  homeMenus: INavigationItem[] = [
    {
      name: NavItemNames.Jobs,
      icon: 'description',
      children: [
        {
          name: NavItemNames.Jobs,
          route: ROUTES.JOBS,
          navigationExtras: {
            queryParams: {
              jobTab: 'active',
            },
          },
          isVisible: this.userHasPermission$(Permission.JobsView),
        },
        e11MenuItemWithRouter(
          NavItemNames.Promotions,
          ROUTES.JOBS_BOOST,
          undefined,
          combineLatest([this.userHasPermission$(Permission.PromotionsView), this.features$(FEATURE_FLAGGED_NAV.EmployerJobPromotions)]).pipe(
            map(([permission, feature]) => permission && feature)
          )
        ),
        e11MenuItemWithRouter(NavItemNames.JobTemplates, ROUTES.JOBS_TEMPLATE, undefined, this.userHasPermission$(Permission.JOB_TEMPLATE_LIST)),
        e11MenuItemWithRouter(NavItemNames.Applications, ROUTES.APPLICATIONS, undefined, this.userHasPermission$(Permission.ApplicationsView)),
      ],
      isOpen: (currentUrl: string) => routePatternMatched(ROUTES.JOBS_BASE, currentUrl),
      isVisible:
        this.userHasPermission$(Permission.JobsView) ||
        this.userHasPermission$(Permission.PromotionsView) ||
        this.userHasPermission$(Permission.ApplicationsView) ||
        this.userHasPermission$(Permission.JOB_TEMPLATE_LIST),
    },
    {
      name: NavItemNames.CandidatePool,
      icon: 'account_circle',
      route: ROUTES.CANDIDATE_POOL,
    },
    e11MenuItemWithTemplateKey('Message', 'message', undefined, 'messaging'),
  ]

  userAccountMenus: INavigationItem[] = [
    {
      name: NavItemNames.UserAccount,
      icon: 'person',
      children: [
        e11MenuItemWithRouter('Personal Information', ROUTES.USER_PERSONAL),
        e11MenuItemWithRouter('Hiring Team Settings', ROUTES.HIRING_TEAM_SETTINGS),
        e11MenuItemWithRouter('Profile Picture', ROUTES.USER_PIC),
        e11MenuItemWithRouter('Profile Video', ROUTES.USER_VIDEO, undefined, this.features$(FEATURE_FLAGGED_NAV.EmployerUserProfileVideo)),
        e11MenuItemWithRouter('Security', ROUTES.USER_SECURITY),
        { name: 'Notifications', route: ROUTES.USER_NOTIFICATIONS },
      ],
      isOpen: (currentUrl: string) => routePatternMatched(ROUTES.ACCOUNT, currentUrl),
    },
  ]
  companyMenus: INavigationItem[] = [
    {
      name: NavItemNames.Reports,
      icon: 'bar_chart',
      route: ROUTES.REPORTS,
      isVisible: this.features$(FEATURE_FLAGGED_NAV.EmployerReporting),
    },
    {
      name: NavItemNames.CompanyAccount,
      icon: 'settings',
      isVisible: this.userHasPermission$(Permission.COMPANY_MANAGE),
      children: [
        e11MenuItemWithRouter('Account Settings', ROUTES.COMPANY_ACCOUNT),
        e11MenuItemWithRouter('Manage Users', ROUTES.MANAGE_USERS),
        e11MenuItemWithRouter('Locations', ROUTES.COMPANY_LOCATIONS),
        e11MenuItemWithRouter('Departments', ROUTES.COMPANY_DEPARTMENTS),

        e11MenuItemWithRouter('Question Library', ROUTES.IQ_LIBRARY, undefined, this.features$(FEATURE_FLAGGED_NAV.InterviewQuestionLibrary)),
        e11MenuItemWithRouter('Compliance', ROUTES.COMPANY_COMPLIANCE),
        e11MenuItemWithRouter(
          'Automation Settings',
          ROUTES.COMPANY_AUTOMATION_SETTINGS,
          undefined,
          combineLatest([
            this.permissionStore.hasAllPermissions([JobAutomationPermissions.ManageAutomation, JobAutomationRolePermissions.AdministrateAutomation]),
            this.features$(FEATURE_FLAGGED_NAV.JobPostAutomation),
          ]).pipe(map(([permission, feature]) => permission && feature))
        ),
      ],
      isOpen: (currentUrl: string) =>
        !routePatternMatched(CAREERS_NAV_ITEM, currentUrl) &&
        routePatternMatched([ROUTES.COMPANY_ACCOUNT, ROUTES.MANAGE_USERS], currentUrl) &&
        !routePatternMatched(ROUTES.CLIENT_MANAGEMENT, currentUrl),
    },
    {
      name: NavItemNames.CareersPage,
      icon: 'web',
      isVisible: this.userHasPermission$(Permission.COMPANY_MANAGE),
      children: [
        e11MenuItemWithRouter('Site Content', ROUTES.COMPANY_CONTENT),
        e11MenuItemWithRouter('Logo and Video', ROUTES.COMPANY_LOGO),
        e11MenuItemWithRouter('Hero Section', ROUTES.COMPANY_HERO_SECTION),
        e11MenuItemWithRouter('Social Media', ROUTES.SOCIAL_MEDIA),
      ],
      isOpen: (currentUrl: string) => routePatternMatched(CAREERS_NAV_ITEM, currentUrl),
    },
  ]

  clientMenus: INavigationItem[] = [
    {
      name: NavItemNames.ClientManagement,
      route: ROUTES.CLIENT_MANAGEMENT,
      icon: 'manage_accounts',
      isVisible: this.userHasPermission$(CustomerPermissions.ViewClient),
      // children: [e11MenuItemWithTemplateKey('Search', 'customer-client', this.userHasPermission$(CustomerPermissions.ViewClient))],
      // isOpen: (currentUrl: string) => routePatternMatched(ROUTES.CLIENT_MANAGEMENT, currentUrl),
    },
  ]
  navigationGroups: Record<TypeOfNav, INavigationItem[]> = {
    homeNav: [
      ...this.homeMenus,
      this.menuItemActions.reportsMenuItem,
      this.menuItemActions.companyMenuItem,
      this.menuItemActions.clientManagementMenuItem,
    ],
    companyNav: [this.menuItemActions.homeMenuItem, ...this.companyMenus, this.menuItemActions.clientManagementMenuItem],
    clientNav: [this.menuItemActions.homeMenuItem, ...this.clientMenus, this.menuItemActions.companyMenuItem],
    userAccountMenus: [
      this.menuItemActions.homeMenuItem,
      ...this.userAccountMenus,
      this.menuItemActions.companyMenuItem,
      this.menuItemActions.clientManagementMenuItem,
    ],
  }
  isMobile$ = this.viewportService.viewportSizeChanged$.pipe(
    map(data => data == 'sm' || data == 'xs' || data == 'md'),
    filter(isNotNil)
  )
  currentMenuStatus: BehaviorSubject<INavigationItem[]> = new BehaviorSubject<INavigationItem[]>(this.navigationGroups.homeNav)

  currentMenuStatus$ = combineLatest([this.currentMenuStatus, this.isMobile$]).pipe(
    map(([menuItem, isMobile]) => {
      if (isMobile) {
        return [
          ...this.homeMenus,
          ...this.companyMenus.map(this.transformPositionValue),
          ...this.userAccountMenus.map(this.transformPositionValue),
          ...this.clientMenus.map(this.transformPositionValue),
        ]
      }
      return menuItem
    })
  )

  mainLayoutScrollableElement?: ElementRef<HTMLDivElement>

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private viewportService: ViewportService,
    private permissionStore: PermissionStore,
    private configStore: ConfigStore
  ) {
    this.routeChangeListener()
  }

  private routeChangeListener() {
    // Listen to route changes to update the active menu group, No need to unsubscribe as this service is a singleton/router service
    this.router.events.pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationStart)).subscribe({
      next: currentRoute => this.handleRouteChange(currentRoute as NavigationEnd | NavigationStart),
    })
  }

  private handleRouteChange(currentRoute: NavigationEnd | NavigationStart) {
    const { url: currentUrl } = currentRoute as NavigationEnd

    const routeMenuGroupMap: Record<string, TypeOfNav> = {
      [ROUTES.ACCOUNT]: 'userAccountMenus',
      [ROUTES.CLIENT_MANAGEMENT]: 'homeNav',
      [ROUTES.COMPANY_ACCOUNT]: 'companyNav',
      [ROUTES.MANAGE_USERS]: 'companyNav',
      [ROUTES.JOBS_BASE]: 'homeNav',
    }

    const activeMenuGroup = this.findActiveMenuGroup(currentUrl, routeMenuGroupMap)
    if (activeMenuGroup) {
      this.changeActive(activeMenuGroup)
    }
  }

  private findActiveMenuGroup(currentUrl: string, routeMenuGroupMap: Record<string, TypeOfNav>): TypeOfNav | null {
    for (const key of Object.keys(routeMenuGroupMap)) {
      if (routePatternMatched(key, currentUrl)) {
        return routeMenuGroupMap[key]
      }
    }
    return null
  }

  transformPositionValue = (menuItem: INavigationItem) => {
    return {
      ...menuItem,
      position: 'main', // Important to regroup for mobile
    }
  }

  changeActive(navGroup: TypeOfNav) {
    this.currentMenuStatus.next(this.navigationGroups[navGroup])
  }

  toClientManagement() {
    this.router.navigate([ROUTES.CLIENT_MANAGEMENT])
  }

  toLogin() {
    this.router.navigate([ROUTES.LOGIN])
  }

  toHome() {
    this.router.navigate([ROUTES.HOME])
  }

  toJobNotFound() {
    this.router.navigate([ROUTES.JOBS_NOT_FOUND])
  }

  toJobPost(jobPostContentId: string) {
    this.router.navigate([ROUTES.JOB_POST, jobPostContentId])
  }
  toJobProfile(jobProfileContentId: string) {
    this.router.navigate([ROUTES.JOB_PROFILE, jobProfileContentId])
  }

  // TODO: switch away from this - the form of the applicationId should only live in one place
  toCandidateDetail(jobId: string, candidateId: string) {
    this.router.navigate(['jobs/candidates/detail/', `${jobId}_${candidateId}`, jobId])
  }

  toCandidateDetailPage(jobId: string, applicationId: string, tab: CandidateDetailTabs = CandidateDetailTabs.Profile) {
    return this.router.navigate(['jobs/candidates/detail/', applicationId, jobId], { queryParams: { tab } })
  }

  toCandidatePoolMessage(candidatePoolId: string) {
    return this.router.navigate(['/candidate-pool/candidates/', candidatePoolId])
  }

  // TODO: Move this to a component store
  setCandidateNavSource(value: CandidateNavigationSource) {
    this.candidateNavigationSource.next(value)
  }

  addQueryParams(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    })
  }

  updateQueryParams(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    })
  }

  setMainLayoutScrollableElement(nativeElement: ElementRef) {
    this.mainLayoutScrollableElement = nativeElement
  }

  scrollMainLayoutToTop() {
    if (this.mainLayoutScrollableElement) {
      this.mainLayoutScrollableElement.nativeElement.scrollTop = 0
    }
  }
}
