import { Component, Input } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'ui-compliance-disability-list',
  standalone: true,
  imports: [TranslateModule],
  template: `
    <div class="e11-p-4 e11-border e11-border-skin-app-borders e11-rounded-lg e11-mb-4">
      <div>
        <div class="e11-mb-2 e11-text-sm">
          <p class="e11-font-bold e11-text-sm e11-mb-1">{{ title | translate }}</p>
          <p class="e11-mb-2">
            {{
              'A disability is a condition that substantially limits one or more of your “major life activities.” If you have or have ever had such a condition, you are a person with a disability.'
                | translate
            }}
          </p>
        </div>
        <div>
          <p class="e11-text-sm">{{ ' Disabilities include, but are not limited to:' | translate }}</p>
          <ul class="e11-text-sm">
            <li class="e11-list-disc e11-ml-8">
              {{ 'Alcohol or other substance use disorder (not currently using drugs illegally) ' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Autoimmune disorder, for example, lupus, fibromyalgia, rheumatoid arthritis, HIV/AIDS' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Blind or low vision' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Cancer (past or present)' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Cardiovascular or heart disease' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Celiac disease' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Cerebral palsy' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Deaf or serious difficulty hearing' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Diabetes' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Disfigurement, for example, disfigurement caused by burns, wounds, accidents, or congenital disorders ' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Epilepsy or other seizure disorder' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ "Gastrointestinal disorders, for example, Crohn's Disease, irritable bowel syndrome" | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Intellectual or developmental disability' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Mental health conditions, for example, depression, bipolar disorder, anxiety disorder, schizophrenia, PTSD' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Missing limbs or partially missing limbs' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Mobility impairment, benefiting from the use of a wheelchair, scooter, walker, leg brace(s) and/or other supports' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Nervous system condition, for example, migraine headaches, Parkinson’s disease, multiple sclerosis (MS)' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{
                'Neurodivergence, for example, attention-deficit/hyperactivity disorder (ADHD), autism spectrum disorder, dyslexia, dyspraxia, other learning disabilities'
                  | translate
              }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Partial or complete paralysis (any cause)' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Pulmonary or respiratory conditions, for example, tuberculosis, asthma, emphysema' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Short stature (dwarfism)' | translate }}
            </li>
            <li class="e11-list-disc e11-ml-8">
              {{ 'Traumatic brain injury' | translate }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  `,
})
export class ComplianceDisabilityListComponent {
  @Input({ required: true }) title: string = 'Disability Definitions'
}
